<!--
  PACKAGE_NAME : src\pages\esp\auth\modal-add-member.vue
  FILE_NAME : modal-add-member
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 권한 사용자 추가 모달
-->
<template>
  <div>
    <esp-dx-data-grid :data-grid="dataGrid" ref="addMemberGrid" />
  </div>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;
  export default {
    components: { EspDxDataGrid },
    props: {
      modalData: Object,
    },
    watch: {},
    data() {
      return {
        rowData: '',
        dataGrid: {
          refName: 'addMemberGrid',
          allowColumnReordering: true,
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: 'calc(100vh - 476px)', // 주석처리시 100%
          apiActionNm: {},
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          columns: [
            {
              caption: '이름',
              dataField: 'loginNm',
              alignment: 'center', // left center right
            },
            {
              caption: '아이디',
              dataField: 'loginId',
              alignment: 'center', // left center right
            },
            {
              caption: '권한',
              dataField: 'authNm',
              alignment: 'center', // left center right
            },
            {
              caption: '계정 상태',
              dataField: 'memberState',
              alignment: 'center', // left center right
              lookup: {
                dataSource: this.$_enums.common.memberState.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {
      authList() {
        return this.modalData.authList;
      },
    },
    methods: {
      async fetchMemberList() {
        const vm = this;
        this.rowData = this.modalData.rowData;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);
            const payload = {
              actionname: 'MEMBER_LIST_ALL',
              data: {
                ...params,
                authId: `<>${vm.rowData.id}`, // 선택된 권한 사용자 제외
                excludeAuthIdFL: vm.$_enums.common.stringUsedFlag.YES.value, // 사용자 권한 검색 제외
                memberStateCd: `<>${vm.$_enums.common.memberState.DELETED.value}`, // 삭제된 계정 제외
                authIdIsNullFl: vm.$_enums.common.stringUsedFlag.YES.value, // 권한 없는 사람도 조회
              },
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },

      setSaveEvent() {
        //데이터 저장
        this.$_eventbus.$on('ModalAddMember:onSaveData', async (e, resolve) => {
          if (this.$refs.addMemberGrid.selectedRowsData.length <= 0) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
            return false;
          }

          let data = {
            selectedRowsData: this.$refs.addMemberGrid.selectedRowsData,
            res: 200,
          };
          resolve(data);
        });
      },

      destroyedSaveEvent() {
        this.$_eventbus.$off('ModalAddMember:onSaveData');
      },
    },
    async created() {
      await this.fetchMemberList();
    },
    mounted() {
      this.setSaveEvent();
    },
    beforeDestroyed() {
      this.destroyedSaveEvent();
    },
  };
</script>

<style scoped></style>
